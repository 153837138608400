<template>
<aheader />
<section class="section">
  <div class="columns">
    <div class="column is-8 is-offset-2">
      Step
      <span class="icon has-text-success is-big">
        <i class="fa-solid fa-3 fa-lg"></i>
      </span>
      <progress class="progress is-success" value="75" max="100"></progress>
    </div>
  </div>
  <section class="section">
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <div class="box">
          <div class="title is-4">
            ご注文の商品
          </div>
          <div class="columns is-centered">
            <div class="column is-narrow">
              <table class="table">
                <tbody>
                  <tr v-for="item, index in $store.state.cartList" :key="index" class="mt-3">
                    <td>
                      <p class="image is-64x64">
                        <img :src="item.icon">
                      </p>
                    </td>
                    <td>
                      <p class="is-size-5-desktop has-text-left">
                        {{item.name}}
                      </p>
                      <template v-if="item.kind != 'one'">
                        <p class="subtitle is-6 has-text-left">
                          {{item.kind}}
                        </p>
                      </template>
                    </td>
                    <td>
                      <p class="has-text-right">
                        {{itemValue(index)}}円
                      </p>
                    </td>
                    <td>
                      ×
                    </td>
                    <td>
                      <p class="has-text-right">
                        {{item.count}}個
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr/>
          <div class="ml-4 is-size-6 has-text-right">
            商品の小計： {{itemPrice()}}円
          </div>
          <div class="ml-4 is-size-6 has-text-right" v-if="$store.state.order_customer.delivery=='send'">
            配送料： {{chargePrice()}}円
          </div>
          <div class="ml-4 is-size-5 has-text-right">
            ご請求額（税込）： {{totalPrice()}}円
          </div>

          <template v-if="$store.state.order_customer.delivery=='shop'">
            <hr/>
            <div class="title is-4 mb-0">
              商品のお受け取り方法
            </div>
            <div class="column">
              <div class="has-text-center is-size-6">
                店にて受け取り
              </div>
            </div>
          </template>
          <template v-else>
            <hr/>
            <div class="title is-4">
              お届け先
            </div>
            <template v-if="$store.state.order_customer.sendInfo">
              <div class="columns">
                <div class="column is-one-quarter is-offset-2">
                  <div class="has-text-left">
                    お名前
                  </div>
                </div>
                <div class="column is-three-quarter">
                  <div class="has-text-left is-size-5">
                      {{$store.state.order_customer.familyName}} {{$store.state.order_customer.firstName}} 様
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-one-quarter is-offset-2">
                  <div class="has-text-left">
                    お届け先住所
                  </div>
                </div>
                <div class="column is-three-quarter">
                  <div class="has-text-left">
                    <span class="has-text-right mt-3 is-size-6">
                      〒 {{$store.state.order_customer.postCode}}
                    </span>
                  </div>
                  <div class="has-text-left is-size-5">
                    <span class="has-text-right ml-3">
                      {{$store.state.order_customer.pref}}{{$store.state.order_customer.address}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="columns mb-4">
                <div class="column is-one-quarter is-offset-2">
                  <div class="has-text-left">
                    電話番号
                  </div>
                </div>
                <div class="column is-three-quarter">
                  <div class="has-text-left is-size-5">
                    {{$store.state.order_customer.tel}}
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="columns">
                <div class="column is-one-quarter is-offset-2">
                  <div class="has-text-left">
                    お名前
                  </div>
                </div>
                <div class="column is-three-quarter">
                  <div class="has-text-left is-size-5">
                      {{$store.state.order_customer.sendName}} 様
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-one-quarter is-offset-2 pb-0">
                  <div class="has-text-left">
                    お届け先住所
                  </div>
                </div>
                <div class="column is-three-quarter pb-0">
                  <div class="has-text-left">
                    <span class="has-text-right mt-3 is-size-6">
                      〒 {{$store.state.order_customer.sendPostCode}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-offset-3 pt-0">
                  <div class="has-text-left is-size-5">
                    <span class="has-text-right ml-3">
                      {{$store.state.order_customer.sendPref}}{{$store.state.order_customer.sendAddress}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="columns mb-4">
                <div class="column is-one-quarter is-offset-2">
                  <div class="has-text-left">
                    電話番号
                  </div>
                </div>
                <div class="column is-three-quarter">
                  <div class="has-text-left is-size-5">
                    {{$store.state.order_customer.sendTel}}
                  </div>
                </div>
              </div>
            </template>
          </template>
          <hr/>
          <div class="title is-4 mb-0">
            領収書
          </div>
          <template v-if="$store.state.order_customer.receipt">
            <div class="column">
              <div class="has-text-center is-size-5">
                必要
              </div>
            </div>
            <div class="column">
              <div class="has-text-center is-size-5">
                宛名： {{$store.state.order_customer.receiptName}} 様
              </div>
            </div>
            <div class="column">
              <div class="has-text-center is-size-6" v-if="$store.state.order_customer.delivery=='send'">
                ※領収書はご購入者様の住所宛にお送りします。
              </div>
            </div>
          </template>
          <template v-else>
            <div class="column">
              <div class="has-text-center is-size-5">
                不要
              </div>
            </div>
          </template>
          <hr/>
          <div class="title is-4">
            ご購入者様
          </div>
          <div class="columns">
            <div class="column is-one-quarter is-offset-2">
              <div class="has-text-left">
                お名前
              </div>
            </div>
            <div class="column is-three-quarter">
              <div class="has-text-left mt-3 is-size-6">
                {{$store.state.order_customer.kanaFamily}} {{$store.state.order_customer.kanaFirst}}
              </div>
              <div class="has-text-left is-size-5">
                  {{$store.state.order_customer.familyName}} {{$store.state.order_customer.firstName}} 様
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column is-one-quarter is-offset-2 pb-0">
              <div class="has-text-left">
                住所
              </div>
            </div>
            <div class="column is-three-quarter pb-0">
              <div class="has-text-left">
                <span class="has-text-right mt-3 is-size-6">
                  〒 {{$store.state.order_customer.postCode}}
                </span>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-offset-3 pt-0">
              <div class="has-text-left is-size-5">
                <span class="has-text-right ml-3">
                  {{$store.state.order_customer.pref}}{{$store.state.order_customer.address}}
                </span>
              </div>
            </div>
          </div>
          <div class="columns mb-4">
            <div class="column is-one-quarter is-offset-2">
              <div class="has-text-left">
                電話番号
              </div>
            </div>
            <div class="column is-three-quarter">
              <div class="has-text-left is-size-5">
                {{$store.state.order_customer.tel}}
              </div>
            </div>
          </div>
          <div class="columns mb-4">
            <div class="column is-one-quarter is-offset-2">
              <div class="has-text-left">
                メールアドレス
              </div>
            </div>
            <div class="column is-three-quarter">
              <div class="has-text-left is-size-5">
                {{$store.state.order_customer.eMail}}
              </div>
            </div>
          </div>
          <div class="columns mb-4">
            <div class="column is-one-quarter is-offset-2">
              <div class="has-text-left">
                性別
              </div>
            </div>
            <div class="column is-three-quarter">
              <div class="has-text-left is-size-5">
                {{$store.state.order_customer.picked}}
              </div>
            </div>
          </div>
          <div class="columns mb-4">
            <div class="column is-one-quarter is-offset-2">
              <div class="has-text-left">
                生年月日
              </div>
            </div>
            <div class="column is-three-quarter">
              <div class="has-text-left is-size-5">
                {{$store.state.order_customer.birthYear}}{{$store.state.order_customer.birthMonth}}{{$store.state.order_customer.birthDay}}
              </div>
            </div>
          </div>

          <hr/>
          <div class="title is-4">
            お支払い方法（クレジットカード）
          </div>

          <div class="columns is-centered">
            <div class="column is-8" id="payment-element">
              <!--Stripe.js injects the Payment Element-->
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

  <div class="columns" v-show="errorMsg != ''" >
    <div class="column error-message notification is-danger is-light" v-html="errorMsg"></div>
  </div>

  <div class="columns">
    <div class="column is-3 is-offset-2">
      <button class="button has-background-info-dark has-text-white-ter" @click="pushPage('order_delivery')">
        <span class="icon is-small">
          <i class="fa-solid fa-arrow-left"></i>
        </span>
        <span>
          前に戻る
        </span>
      </button>
    </div>
    <div class="column is-3 is-offset-2">
      <button class="button has-background-info-dark has-text-white-ter" @click="pay()" :disabled="$store.state.doingPayment">
        <span>
          購入する
        </span>
        <span class="icon is-small">
          <i class="fa-solid fa-arrow-right"></i>
        </span>
      </button>
    </div>
  </div>
</section>
<afooter/>
</template>
<script>
import {
  doc,
  collection,
  serverTimestamp,
  setDoc
} from "firebase/firestore";
import { getAuth } from 'firebase/auth';

export default {
  data() {
    return {
      orderID: "",
      stripeObj: null,
      stripeElements: null,
      errorMsg: ""
    }
  },
  computed: {
  },
  created() {
    if (this.$store.state.doingPayment) {
      this.checkPaymentStatus();
    }
  },
  mounted() {
    getAuth()
    if (!this.$store.state.doingPayment) {
      this.initStripe();
    }
    this.scrollTop();
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    },
    async initStripe() {
      //console.log(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
      this.stripeObj = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);

      let amount = this.$store.getters.totalPrice;
      if (this.$store.state.order_customer.delivery=='send') {
        amount += this.$store.state.order_customer.sendCharge;
      }
      let params = new URLSearchParams();
      params.append('amount', amount);
      const response = await this.$axios.post(process.env.VUE_APP_FUNCTIONS_URL_CREATE_PAYMENT_INTENT, params);
      const clientSecret = response.data.clientSecret;

      const appearance = {
        theme: 'flat',
      };
      this.stripeElements = this.stripeObj.elements({ appearance, clientSecret });

      const paymentElement = this.stripeElements.create("payment",{
        fields: {
          billingDetails: {
            address: {
              country: "never"
            }
          }
        }
      });
      paymentElement.mount("#payment-element");
      this.$store.commit("setStripeClientSecret", clientSecret);
    },
    itemValue(index) {
      //console.log(this.$store.state.cartList[index])
      return this.$store.state.cartList[index].value.toString().replace(/\B(?=(\d{3})+$)/g, ',');
    },
    itemPrice() {
      return this.$store.getters.totalPrice.toString().replace(/\B(?=(\d{3})+$)/g, ',');
    },
    totalPrice() {
      if (this.$store.state.order_customer.delivery=='send') {
        return (this.$store.getters.totalPrice+this.$store.state.order_customer.sendCharge).toString().replace(/\B(?=(\d{3})+$)/g, ',');
      } else {
        return this.$store.getters.totalPrice.toString().replace(/\B(?=(\d{3})+$)/g, ',');
      }
    },
    chargePrice() {
      return this.$store.state.order_customer.sendCharge.toString().replace(/\B(?=(\d{3})+$)/g, ',');
    },
    pushPage(page) {
      this.$router.push(page);
    },
    addErrorMsg(msg) {
      if (this.errorMsg != "") this.errorMsg += "<br/>";
      this.errorMsg = this.errorMsg + msg;
    },
    getOrderID() {
      let _newDocRef = null;
      if (this.$store.state.account_info.eMail != "") {
        _newDocRef = doc(collection(this.$firestore_db, "orders", this.$store.state.account_info.eMail, "order_list"));
      } else {
        _newDocRef = doc(collection(this.$firestore_db, "orders", "general", "order_list"));
      }
      return _newDocRef.id;
    },
    async pay() {
      if (this.$store.state.doingPayment) {
        return false;
      }
      this.errorMsg = "";
      if (this.$store.state.cartList.length == 0) this.addErrorMsg("ご注文内容がクリアされているため購入手続きができません。");
      if (this.errorMsg != "") {
        return false;
      }

      this.$store.commit("setDoingPayment", true);

      let elements = this.stripeElements;
      const { error } = await this.stripeObj.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
          payment_method_data: {
            billing_details: {
              address: {
                country: "JP"
              }
            }
          }
        },
      });

      if (error.type === "card_error" || error.type === "validation_error") {
        this.addErrorMsg(error.message);
        this.$store.commit("setDoingPayment", false);
      } else {
        this.addErrorMsg("決済処理で予期しないエラーが発生しました。");
        this.$store.commit("setDoingPayment", false);
      }
    },
    async checkPaymentStatus() {
      this.stripeObj = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
      const { paymentIntent } = await this.stripeObj.retrievePaymentIntent(this.$store.state.stripeClientSecret);

      switch (paymentIntent.status) {
        case "succeeded":
          this.createDoc(paymentIntent);
          //オーダー関連のstoreデータをクリア
          this.$store.commit('clear4ordered');
          //注文完了画面へ
          this.$store.state.order_id = paymentIntent.id;
          this.$router.push('order_complete');
          break;
        case "processing":
          this.addErrorMsg("Your payment is processing.");
          break;
        case "requires_payment_method":
          this.addErrorMsg("Your payment was not successful, please try again.");
          break;
        default:
          this.addErrorMsg("Something went wrong.");
          break;
      }
    },
    async createDoc(creditData) {
      const data = {
        customer: this.$store.state.order_customer,
        items: this.$store.state.cartList,
        credit: creditData,
        createdBy: this.$store.state.account_info,
        createdAt: serverTimestamp()
      };
      if (this.$store.state.account_info.eMail != "") {
        await setDoc(doc(this.$firestore_db, 'orders', this.$store.state.account_info.eMail, "order_list", creditData.id), data);
      } else {
        await setDoc(doc(this.$firestore_db, 'orders', "general", "order_list", creditData.id), data);
      }
    }
  }
}
</script>

<style scoped>

</style>
